import "@popperjs/core";
import "bootstrap";
import { BlazorInterop } from "./blazor/index";
import "../styles/index.scss";

declare const APP_ENVIRONMENT: "Development" | "Production";

(() =>
{
	console.log(`Risk Transfer Management System - ${APP_ENVIRONMENT}`);

	// eslint-disable-next-line
	// @ts-ignore
	window.UltimediaRtmsBlazorInterop = new BlazorInterop();
})();